<template>
    <div>
      <el-row>
        
         <div style="float: right;">
          <el-select v-model="serviceStation" placeholder="選擇營運商(All)"   class="filter-item" clearable v-if="serviceOptions.length>0">
            <el-option v-for="item in serviceOptions" :key="item.id"
            :label="item.name " :value="item.id"   />
           </el-select>


           <el-select v-model="chargePointOption" placeholder="選擇充電站(All)" class="filter-item" clearable>

           <el-option v-for="item in  optionsSelect" :key="item.stationId" :label="item.stationName" :value="item.stationId" />
           </el-select>
           <el-select v-model="alertType" placeholder="選擇事件類型(All)" class="filter-item" clearable>

          <el-option v-for="item in alertOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
          </el-select>
        <!--   <el-date-picker
               v-model="dateRange"
               class="filter-item"
               type="daterange"
               value-format="yyyy-MM-dd"
               range-separator="-"
               start-placeholder="開始日期"
               end-placeholder="結束日期">
          </el-date-picker>
       -->
       <el-date-picker
               v-model="startDate"
               class="filter-item"
               type="date"
               value-format="yyyy-MM-dd"
             >
          </el-date-picker>
          <el-date-picker
               v-model="endDate"
               class="filter-item"
               type="date"
               value-format="yyyy-MM-dd"
              >
          </el-date-picker>
          <el-button
         style="float: right; margin: 7px"
         size="small"
         type="primary"
         icon="el-icon-search"
         @click="searchRecord('2')"
       >
         查詢
       </el-button>
        </div>
       </el-row>


       <br>
       <el-divider content-position="left" >
        <span style="font-weight: bold; font-size: 1.2em;">設備告警與交易告警列表</span>
       </el-divider>

       <el-row>
       <el-table
         :data="recordList" style="width: 100%;"
         :cell-style="{padding: '0', height: '40px'}"
         @sort-change="tableSortChange"
       >
         <el-table-column align="center" prop="stationName" label="充電站" sortable />
         <el-table-column align="center" prop="pointName" label="充電樁" sortable />
         <el-table-column align="center" prop="connector" label="充電槍"  />
         <el-table-column align="center" prop="type" label="事件類型" sortable />
       <!--  <el-table-column align="center" prop="code" label="事件代碼"  />-->
         <el-table-column align="center" prop="message" label="事件訊息"  />
         <el-table-column align="center" prop="createOn" label="建立時間" sortable />
       </el-table>
       <!--分頁組件-->
       <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="size" @pagination="searchRecord('3')" :page-sizes="[10]"/>
       </el-row>

       <br>
       <el-divider content-position="left" >
        <span style="font-weight: bold; font-size: 1.2em;">超約告警列表</span>
       </el-divider>

       <el-row>
       <el-table
         :data="overAlertList" style="width: 100%;"
         :cell-style="{padding: '0', height: '40px'}"
         @sort-change="tableSortChange"
       >
         <el-table-column align="center" prop="stationName" label="充電站" min-width="15%" sortable />
         <el-table-column align="center" prop="pointName" label="電錶"  min-width="15%" sortable />
         <el-table-column align="center" prop="type" label="事件類型" min-width="15%" sortable />
         <el-table-column align="center" prop="message" label="事件訊息"   min-width="40%" />
         <el-table-column align="center" prop="createOn" label="建立時間"  min-width="15%"  sortable />
       </el-table>
   <!--分頁組件-->
       <pagination v-show="totalOverAlert>0" :total="totalOverAlert" :current.sync="paginationOverAlert" :size.sync="size" @pagination="searchOverAlert('2')" :page-sizes="[10]"/>
       </el-row>
    </div>

</template>


   <script>
   import Pagination from '@/components/Pagination'
   import { mixins } from '@/views/common/mixins.js'
   import {refreshToken,stopRefreshToken} from "@/utils/auth";

   export default {
       name: 'record',
       components: {
           Pagination
       },
       mixins: [mixins],
       data() {
           return {
               dialogWidth: 0,
               total: 0,
               totalOverAlert:0,
               typeAlert:"超約告警",
               recordList: [],
               overAlertList:[],   //20240715 add
               targetUrlNotify:undefined,
               alertType:undefined,
               chargePointOption:undefined,
               serviceStation:undefined,
               stationSelect:undefined,
               serviceOptions:[],
               optionsSelect:[],
               pageSelect:0, 
               pageOverAlert:0,  //20240715 add
               size:10,
               listLoading: false,
               listQuery: {
                   type: 'general',
                   station: "",
                   name: undefined,
                   after: undefined,
                   before: undefined,
                   page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                   size: 10
               },
            alertOptions: [{
              value: '設備告警',
              label: '設備告警'
            }, {
              value: '交易告警',
              label: '交易告警'
            },{
              value: '超約告警',
              label: '超約告警'
            }
             /*{
              value: '系統告警',
              label: '系統告警'
            }*/
          ],
               paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
               //dateRange: [],
               startDate:undefined,
               endDate:undefined,

               paginationOverAlert:1,
               //dialogSettingVisible: false
           };
       },
       computed: {
       /* optionsSelect() {
       console.log("this.stationSelect:", this.stationSelect);
        return this.$store.state.chargingStations.filter(item => item.value ==  this.stationSelect)
     },*/
       },
     watch: {
       paginationSelectedPage: {
         handler: function(newValue) {
           this.pageSelect = newValue - 1
         }
       },
       paginationOverAlert: {
         handler: function(newValue) {
           this.pageOverAlert = newValue - 1
         }
       },
       alertType:function(val)
       {
     
        if(val=='')
           this.alertType=null;
       },
       chargePointOption:function(val)
       {
    
        if(val=='')
           this.chargePointOption=null;

       },

       serviceStation:function(val)
       {

        this.optionsSelect=null;
        /* 因為route傳過來的serviceId等同讓listQuery.serviceId發生變化,為了避免this.chargePointOption在這被洗成null 因此加上判斷 */
        if(this.$route.params.stationId != undefined) { 
          this.chargePointOption = this.$route.params.stationId //若沒有轉成數值,那el-select選單無法以key導入使之正確呈現名稱
          this.$route.params.stationId = undefined 
        } else  
          this.chargePointOption=null;
        /*--------------------------------------------------------------------------------------------------------------------*/
      
        this.stationSelect =val;

        if(val == null ||val =='')
        {
          this.serviceStation=null
          this.targetUrl =  "/api/v1/notifylist/stationidListByserviceId?"
        }
        else
        {
          this.targetUrl =  "/api/v1/notifylist/stationidListByserviceId?serviceId="+val
        }

        this.axios.get(this.targetUrl).then(res => {
        this.optionsSelect=res.data
        })
       }
     },
     async created() {
      this.dialogWidth = this.setDialogWidth()
       
      this.getServiceOptions()
      this.getChargPoint()  //0408加入

      /* Dashboard.vue route過來的參數 by企分會議需求 */
      if(this.$route.params.serviceId != undefined) this.serviceStation = this.$route.params.serviceId
      if(this.$route.params.stationId != undefined) this.chargePointOption = this.$route.params.stationId    
      /* Dashboard.vue route過來的參數 by企分會議需求 */


       if(this.$route.params.start!=null)
       {
        this.startDate=this.$route.params.start
       this.endDate=this.$route.params.end
       this.searchRecord('0') 
       }
       else
          this.searchRecord('1')  //1: create, 2:查詢, 3:page   //應該區分如果是從route 過來的為0 10/18
       
       this.searchOverAlert('1')


       refreshToken()
     },
     beforeDestroy() {
        stopRefreshToken()
     },

     mounted() {
       window.onresize = () => {
         return (() => {
           this.dialogWidth = this.setDialogWidth()
         })()
       }
     },

     methods: {

        //取得營運商列表
        getServiceOptions() {
            this.listLoading = true
            this.axios.get('/api/v1/common/service/enabled').then(res => {
            //this.serviceList = res.data
            this.serviceOptions=res.data
             })
          },

      //取得營運商
        getService()
          {
            this.targetUrl =  "/api/v1/common/service/enabled"
            this.axios.get(this.targetUrl).then(res => {
               this.serviceOptions=res.data
            })
          },

          //取得充電站
        getChargPoint(){
          if(this.serviceOptions.length==0)  //如果沒有營運商選擇
          {
          this.targetUrl =  "/api/v1/notifylist/stationidListByserviceId?serviceId="+this.$store.state.currentAccount.serviceInfo.id
          this.axios.get(this.targetUrl).then(res => {
          this.optionsSelect=res.data
          })
         }
        },

       searchRecord(val) {
       // this.targetUrlNotify="/api/v1/notifylist/notifyLog?serviceId=1&stationId=1&type=設備告警&startOn=2024-01-24&endOn=2024-01-25"
       this.recordList=[]

       if(val=='0')
       {
          this.total=0
          this.pageSelect=0
          this.searchOverAlert('1')   //20240715 add test
       }
       else if(val=='1') //從create來, 否則就是從分頁來
        {
          this.startDate=this.getNDaysAgo(7, new Date())
          this.endDate=new Date().toISOString().substring(0,10)
        
          this.total=0
          this.pageSelect=0
          this.searchOverAlert('1')   //20240715 add test

        }
        else if(val=='2') //從查詢來
        {
       
          this.total=0
          this.pageSelect=0
          if(this.alertType=='超約告警' || this.alertType==null)
          {
            this.searchOverAlert('1')   //20240715 add test
          }
          else 
          {
            this.totalOverAlert=0
            this.pageSelect=0
            this.overAlertList=[]
          }
             
        } 
  
       this.targetUrlNotify="/api/v1/notifylist/notifyLog?"
        if(this.serviceStation != undefined || this.serviceStation != null)
            this.targetUrlNotify += "serviceId="+this.serviceStation
        else if(this.serviceOptions.length==0)
            this.targetUrlNotify += "serviceId="+this.$store.state.currentAccount.serviceInfo.id

        if(this.chargePointOption != undefined || this.chargePointOption != null)
            this.targetUrlNotify=this.targetUrlNotify+"&stationId="+this.chargePointOption

        if(this.alertType != null || this.alertType != undefined )
            this.targetUrlNotify +="&type="+this.alertType

        if(this.startDate  != null  )
          {
            if(this.startDate.length !=0)
             this.targetUrlNotify += "&startOn="+this.startDate + "&endOn=" +this.endDate
          }
               

        this.targetUrlNotify =  this.targetUrlNotify + "&size=10&page="+this.pageSelect
        this.axios.get(this.targetUrlNotify).then(res => {
              this.recordList=res.data.content
              this.total=res.data.totalElements
            })
       },

       searchOverAlert(val)
       {
        this.overAlertList=[]

       if(val=='1') //從查詢按鈕或create來, 否則就是從分頁來
        {
          this.totalOverAlert=0
          this.pageSelect=0
          
        }  

       this.targetUrlNotify="/api/v1/notifylist/notifyOverLog?"
        if(this.serviceStation != undefined || this.serviceStation != null)
            this.targetUrlNotify += "serviceId="+this.serviceStation
        else if(this.serviceOptions.length==0)
            this.targetUrlNotify += "serviceId="+this.$store.state.currentAccount.serviceInfo.id

        if(this.chargePointOption != undefined || this.chargePointOption != null)
            this.targetUrlNotify=this.targetUrlNotify+"&stationId="+this.chargePointOption

          //if(this.alertType != null || this.alertType != undefined )
            this.targetUrlNotify +="&type="+this.typeAlert

            if(this.startDate  != null  )
              {
                if(this.startDate.length !=0)
                   this.targetUrlNotify += "&startOn="+this.startDate + "&endOn=" +this.endDate
              }
               

        this.targetUrlNotify =  this.targetUrlNotify + "&size=10&page="+this.pageOverAlert
      
        this.axios.get(this.targetUrlNotify).then(res => {
              this.overAlertList=res.data.content
             
              this.totalOverAlert=res.data.totalElements
            })
       },
       getNDaysAgo(N, date) {
       const daysAgo = new Date(date.getTime())
       daysAgo.setDate(date.getDate() - N)
       return daysAgo.toISOString().substring(0,10)
    },

     }
   }

   </script>
